
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.selected-users {
  margin: 20px 0 0 0;
  &__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 5px 0;
    flex-wrap: wrap;
    &-name {
      flex: 1;
    }
    &-delete {
      cursor: pointer;
      margin-left: 20px;
      &_without-input {
        margin-left: auto;
      }
    }
  }
}
.user {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 100%;
  @include for-size(phone-portrait-down) {
    width: 100%;
    margin: 0 0 10px 0;
  }
  &__avatar {
    margin: 0 20px 0 0;
    width: 40px;
    height: 40px;
    flex-shrink: 0;
  }
  &__name {
    font-size: 14px;
    line-height: 20px;
    color: #101828;
    transition: color 0.3s;
    &-fio {
      margin: 0 16px 0 0;
    }
  }
  &__custom-login {
    margin: 0 0 0 auto;
    @include for-size(phone-portrait-down) {
      margin: 0;
    }
  }
  &__email {
    display: inline-block;
    color: rgba(16, 24, 40, 0.4);
  }
  &__appointment-name {
    color: $color-primary-1-day;
  }
  &__email, &__appointment-name {
    font-size: 12px;
    line-height: 16px;
  }
}
